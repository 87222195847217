import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Dealer from '../Dealer'
import axiosInstance from '../../../../../../../Config/axios'
import { toast, Slide } from 'react-toastify'
import ToastContent from '../../../../../../../CommonComponent/Toast'
import CommunityCards from '../Dealer/CommunityCard'
import PokerActions from '../Actions'
import Player from '../Players'
import { gsap } from 'gsap';
import coins from "../../../../../../../assets/img/games/coins_transparent_yellow.png"
// import Banner from "../../../../../../../Image/banner.webp"
import Banner from "../../../../../../../assets/img/games/poker_background_image.png";

import CurrentPlayerCards from '../../CurrentPlayer/CurrentPlayerCards'
import hotToast from 'react-hot-toast'
import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import MicIcon from '@mui/icons-material/Mic';
import { useSpeechSynthesis } from 'react-speech-kit';
import { GiCardPick } from "react-icons/gi";
import Card from '../Cards'
const Home = ({ showResult, gameInfo, socket, fetchGameInfo }) => {
    const { speak } = useSpeechSynthesis();
    const usersRef = useRef([]);
    const navigate = useNavigate()
    let minimumBetAmount = gameInfo?.minimumBet || 0
    const { gameAddress } = useParams()

    const userData = JSON.parse(localStorage.getItem('userData'))

    const [gameMoveStatus, setGameMoveStatus] = useState({ round: 0, buttons: [], userId: 0, maxBetAmount: minimumBetAmount, foldedPlayers: [], playersBalance: [], timer: 0, potMoney: 0, betStatus: 'betting', tableBetAmount: 0 })

    const [servedCards, setServedCards] = useState({
        'CommunityCards': [],
        'PlayerCards': [],
        'CommunityCards1': [],
        'CommunityCards2': [],
    })
    const [gameResult, setGameResult] = useState({})

    const serveCards = async () => {
        const allPlayers = gameInfo.players
        try {
            const bodyData = { players: allPlayers.map(item => item.id), gameAddress: gameAddress }

            const response = await axiosInstance.post('/api/game/poker/serve-cards', bodyData)

            const { data: responseData } = response
            if (!responseData.status) {
                toast.error(<ToastContent status='error' message={responseData.message} />, {
                    transition: Slide, autoClose: 2000, position: 'bottom-center', theme: 'dark'
                })
            } else {
                const playersData = responseData.data['PlayerCards'];
                const communityData = responseData.data['CommunityCards'];
                const communityData1 = responseData.data['CommunityCards1'];
                const communityData2 = responseData.data['CommunityCards2'];

                const playerCards = allPlayers.map((player, index) => {

                    let user = playersData.filter(item => item['Name'] === player?.address)
                    user = user[0];
                    return { ...player, 'Hand': user['Hand'] }

                });
                if (responseData.data.gameResult && showResult) {
                    setGameResult(responseData.data.gameResult)
                }
                setServedCards({ 'CommunityCards': communityData, 'PlayerCards': playerCards, 'CommunityCards1': communityData1, 'CommunityCards2': communityData2 })

            }

        } catch (error) {
            toast.error(<ToastContent status='error' message={error.message} />, {
                transition: Slide, autoClose: 2000, position: 'bottom-center', theme: 'dark'
            })
        }
    }

    const finishGame = async (winner) => {
        try {
            const response = await axiosInstance.post('/api/game/poker/finish-game', { gameAddress, userAddress: winner });

            const { data: responseData } = response;

            if (!responseData.status) {
                toast.error(<ToastContent status='error' message={response.data.message} />, {
                    transition: Slide, autoClose: 2000, position: 'bottom-center', theme: 'dark'
                })
            } else {

            }
        } catch (error) {
            toast.error(<ToastContent status='error' message={error.message} />, {
                transition: Slide, autoClose: 2000, position: 'bottom-center', theme: 'dark'
            })
        }
    }

    // fetch player move
    const fetchPlayerMove = async () => {
        try {
            const bodyData = { gameAddress };
            const response = await axiosInstance.post('/api/game/poker/action-status', bodyData);

            const { data: responseData } = response;

            if (!responseData.status) {

            } else {
                if (responseData.data.winner) {
                    const winner = responseData.data.winner;

                    const userData = JSON.parse(localStorage.getItem('userData'));
                    if (userData?.address == winner) {
                        finishGame(winner);
                    }
                } else {
                    const newResponseData = responseData.data;
                    console.log(newResponseData)
                    if (((newResponseData?.userId === userData?.id) && (!newResponseData?.buttons?.includes('BigBlind')))) {
                        const message = `It's your turn to play`;
                        speak({ text: message });
                    }
                    setGameMoveStatus(newResponseData)
                }

            }

        } catch (error) {
            toast.error(<ToastContent status='error' message={error.message} />, {
                transition: Slide, autoClose: 2000, position: 'bottom-center', theme: 'dark'
            })
        }
    }


    useEffect(() => {
        if (!socket) return
        socket.on('potUpdated', (data) => {
            const { isStarted, userId, action, amount } = data;
            if (action && !['blind', 'big_blind'].includes(action)) {
                if (action == 'fold') {
                    const message = `Player has folded`;
                    speak({ text: message });
                } else {
                    const message = `${action} with ${amount} Solana`;
                    speak({ text: message });
                }
            }

            if (action === 'blind' || action === 'big_blind') {
                const targetId = 'pot_money';
                const playerId = `player_${userId}`;
                animateFlowToPot(playerId, targetId);
            }

            if (isStarted) {
                fetchPlayerMove();
                serveCardsToPlayers();
                const totalPlayers = allPlayersCards.length;
                setTimeout(() => {
                    fetchPlayerMove();
                }, totalPlayers * 750 * 2);
            } else {
                fetchPlayerMove();
            }
        })

        socket.on("gameOver", (data) => {
            const { winner, gameAddress: responseGameAddress } = data;
            const userData = JSON.parse(localStorage.getItem('userData'));
            let message = 'Game Over! You have won the game'
            console.log('game over', data)
            if (data?.winner?.includes(userData?.address)) {
                message = 'Game Over! You have won the game'
            } else {
                message = 'Game Over!'
            }
            speak({ text: message });
            if (responseGameAddress === gameAddress) {
                fetchGameInfo();
                setTimeout(() => {
                    hotToast.success(message, { duration: 2000, position: 'bottom-center' })
                }, 2000);
            }
        });

        return () => {
            socket.off('potUpdated')
            socket.off('gameOver')
        }
    }, [servedCards])


    useEffect(() => {
        if (!gameAddress) return;
        if (servedCards.PlayerCards.length == 0) return;
    }, [servedCards])

    useEffect(() => {
        if (showResult) return;
        if (!gameAddress) return;
        if (servedCards.PlayerCards.length == 0) return;
    }, [servedCards])

    useEffect(() => {
        if (!gameAddress) return;
        serveCards()
        if (showResult) return
        fetchPlayerMove()
    }, [])


    let allPlayersCards = servedCards['PlayerCards']

    const currentUserPlayerCards = allPlayersCards.filter(player => player['address'] === userData?.address)
    const otherPlayers = allPlayersCards.filter(player => player['address'] !== userData?.address)

    const newPlayers = [...currentUserPlayerCards, ...otherPlayers].map((item, index) => {
        return { index, ...item }
    })

    let userTurn = servedCards['PlayerCards'].filter(player => player['userId'] === gameMoveStatus?.currentPlayer)

    if (userTurn.length > 0) {
        userTurn = userTurn[0]
    } else {
        userTurn = {}
    }

    let allCommunityCards = []

    if (gameMoveStatus.round === 0) {
        allCommunityCards = []
    } else if (gameMoveStatus.round == 1) {
        allCommunityCards = servedCards['CommunityCards']
    } else if (gameMoveStatus.round == 2) {
        allCommunityCards = servedCards['CommunityCards'].concat(servedCards['CommunityCards1'])
    } else if (gameMoveStatus.round == 3) {
        allCommunityCards = servedCards['CommunityCards'].concat(servedCards['CommunityCards1']).concat(servedCards['CommunityCards2'])
    } else {
        allCommunityCards = servedCards['CommunityCards'].concat(servedCards['CommunityCards1']).concat(servedCards['CommunityCards2'])
    }


    const showActionButtons = () => {

        if (gameMoveStatus?.userId === userData?.id && gameMoveStatus?.buttons?.length > 0) {
            return true;
        }

        return false;
    }

    const getClassName = (index) => {
        if (index == 0) {
            return `absolute -bottom-10 left-1/2 -translate-x-1/2 -translate-y-1/2`;
        }
        if (index == 1) {
            return `absolute bottom-10 left-1/4  -translate-x-1/2`;
        }
        if (index == 2) {
            return `absolute left-5 bottom-1/4 translate-x-1/4`;
        }
        if (index == 3) {
            return `absolute left-0 top-1/4 translate-x-1/4`;
        }
        if (index == 4) {
            return `absolute top-0 left-1/4 -translate-x-1/4 `;
        }
        if (index == 5) {
            return `absolute top-0 right-1/4 translate-x-1/4 `;
        }
        if (index == 6) {
            return `absolute right-0 top-1/4 -translate-x-1/4`;
        }
        if (index == 7) {
            return `absolute right-0 bottom-1/4 -translate-x-1/4`;
        }
        if (index == 8) {
            return `absolute right-1/4 -bottom-0 translate-x-1/2 `;
        }
    }

    const getPlaceHolderPlayerPosition = (index, totalPlayers) => {


        if (totalPlayers == 9) {
            if (index == 0) {
                return `absolute -bottom-10 left-1/2 -translate-x-1/2 -translate-y-1/2`;
            }
            if (index == 1) {
                return `absolute bottom-10 sm:bottom-0 left-1/4  -translate-x-1/2`;
            }
            if (index == 2) {
                return `absolute left-0 sm:left-5 bottom-1/4 `;
            }
            if (index == 3) {
                return `absolute left-0 sm:left-5 top-1/4 `;
            }
            if (index == 4) {
                return `absolute top-0 left-1/4 -translate-x-1/4 `;
            }
            if (index == 5) {
                return `absolute top-4 sm:top-0 right-1/4 translate-x-1/4 `;
            }
            if (index == 6) {
                return `absolute right-6 sm:right-16 top-1/4 `;
            }
            if (index == 7) {
                return `absolute right-6 sm:right-16 bottom-1/4`;
            }
            if (index == 8) {
                return `absolute right-1/4 bottom-10 sm:bottom-0 translate-x-1/2 `;
            }
            if (index == 9) {
                return `absolute right-1/4 bottom-10 sm:bottom-0 translate-x-1/2 `;
            }

        }

    };

    let samplePlayers = newPlayers
    if (samplePlayers.length == 2) {
        const firstPlayer = samplePlayers[0];
        const secondPlayer = samplePlayers[1];
        samplePlayers = [firstPlayer, { fake: true }, { fake: true }, { fake: true }, secondPlayer, { fake: true }, { fake: true }, { fake: true }, { fake: true }];
    } else if (samplePlayers.length == 3) {
        const firstPlayer = samplePlayers[0];
        const secondPlayer = samplePlayers[1];
        const thirdPlayer = samplePlayers[2];
        samplePlayers = [firstPlayer, { fake: true }, { fake: true }, secondPlayer, { fake: true }, { fake: true }, thirdPlayer, { fake: true }, { fake: true }];
    } else if (samplePlayers.length == 4) {
        const firstPlayer = samplePlayers[0];
        const secondPlayer = samplePlayers[1];
        const thirdPlayer = samplePlayers[2];
        const fourthPlayer = samplePlayers[3];
        samplePlayers = [firstPlayer, { fake: true }, { fake: true }, secondPlayer, { fake: true }, thirdPlayer, fourthPlayer, { fake: true }, { fake: true }];
    } else if (samplePlayers.length == 5) {
        const firstPlayer = samplePlayers[0];
        const secondPlayer = samplePlayers[1];
        const thirdPlayer = samplePlayers[2];
        const fourthPlayer = samplePlayers[3];
        const fifthPlayer = samplePlayers[4];
        samplePlayers = [firstPlayer, { fake: true }, { fake: true }, secondPlayer, thirdPlayer, fourthPlayer, fifthPlayer, { fake: true }, { fake: true }];
    } else if (samplePlayers.length == 6) {
        const firstPlayer = samplePlayers[0];
        const secondPlayer = samplePlayers[1];
        const thirdPlayer = samplePlayers[2];
        const fourthPlayer = samplePlayers[3];
        const fifthPlayer = samplePlayers[4];
        const sixthPlayer = samplePlayers[5];
        samplePlayers = [firstPlayer, { fake: true }, { fake: true }, secondPlayer, thirdPlayer, fourthPlayer, fifthPlayer, sixthPlayer, { fake: true }];
    } else if (samplePlayers.length == 7) {
        const firstPlayer = samplePlayers[0];
        const secondPlayer = samplePlayers[1];
        const thirdPlayer = samplePlayers[2];
        const fourthPlayer = samplePlayers[3];
        const fifthPlayer = samplePlayers[4];
        const sixthPlayer = samplePlayers[5];
        const seventhPlayer = samplePlayers[6];
        samplePlayers = [firstPlayer, { fake: true }, secondPlayer, thirdPlayer, fourthPlayer, fifthPlayer, sixthPlayer, seventhPlayer, { fake: true },];
    } else if (samplePlayers.length == 8) {
        const firstPlayer = samplePlayers[0];
        const secondPlayer = samplePlayers[1];
        const thirdPlayer = samplePlayers[2];
        const fourthPlayer = samplePlayers[3];
        const fifthPlayer = samplePlayers[4];
        const sixthPlayer = samplePlayers[5];
        const seventhPlayer = samplePlayers[6];
        const eighthPlayer = samplePlayers[7];
        samplePlayers = [firstPlayer, secondPlayer, thirdPlayer, fourthPlayer, fifthPlayer, sixthPlayer, seventhPlayer, eighthPlayer, { fake: true }];
    } else {
        samplePlayers = samplePlayers;
    }


    const serveCardsToPlayers = () => {
        const totalPlayers = allPlayersCards.length;
        const dealer = document.getElementById('pot_money');
        for (let round = 0; round < 2; round++) {
            allPlayersCards.forEach((player, index) => {
                setTimeout(() => {
                    const card = document.createElement('div');
                    card.className = 'card h-16 w-12 border-2 bg-[#FFD700] rounded-lg shadow-lg absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2';
                    dealer.appendChild(card);

                    const userId = player.userId;
                    const dealerRect = dealer.getBoundingClientRect();
                    const userIndex = samplePlayers.findIndex(item => item.userId === userId);

                    if (!usersRef.current[userIndex]) return;
                    const userRect = usersRef.current[userIndex].getBoundingClientRect();
                    const cardRect = card.getBoundingClientRect();


                    const x = userRect.left + (userRect.width - cardRect.width) / 2 - dealerRect.left + window.scrollX;
                    const y = userRect.top + (userRect.height - cardRect.height) / 2 - dealerRect.top + window.scrollY;

                    gsap.to(card, {
                        duration: 1,
                        x: x,
                        y: y,
                        ease: "power2.inOut",
                        onComplete: () => {
                            card.remove();
                        }
                    });
                }, round * totalPlayers * 500 + index * 500); // Adjust delay for each round and player
            });
        }
    };

    const animateFlowToPot = (playerId, targetId) => {
        const playerElement = document.getElementById(playerId);
        const targetElement = document.getElementById(targetId);

        if (!playerElement || !targetElement) return;

        const playerRect = playerElement.getBoundingClientRect();
        const targetRect = targetElement.getBoundingClientRect();

        const card = document.createElement('img');
        card.className = 'card h-12 w-12 absolute rounded-full rounded-circle';
        card.src = coins;
        card.style.top = `${playerRect.top + window.scrollY}px`;
        card.style.left = `${playerRect.left + window.scrollX}px`;
        document.body.appendChild(card);

        const x = targetRect.left + targetRect.width / 2 - playerRect.left - playerRect.width / 2;
        const y = targetRect.top + targetRect.height / 2 - playerRect.top - playerRect.height / 2;

        gsap.to(card, {
            duration: 0.7,
            x: x,
            y: y,
            onComplete: () => {
                card.remove();
            }
        });
    };

    const handleQuitLobby = () => {
        navigate('/all-game-page')
    }

    const showCurrentPlayerCards = () => {
        return true
    }


    const handleFullScreen = () => {
        let id = document.getElementById('poker_table')

        // if full screen is enable then disable it
        if (document.fullscreenElement || document.webkitFullscreenElement || document.msFullscreenElement || document.mozFullScreenElement) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) { /* Safari */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE11 */
                document.msExitFullscreen();
            } else if (document.mozCancelFullScreen) { /* Firefox */
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) { /* Chrome */
                document.webkitCancelFullScreen();
            } else if (document.webkitCancelFullscreen) { /* Chrome */
                document.webkitCancelFullscreen();
            }
            return;
        }

        if (id.requestFullscreen) {
            id.requestFullscreen();
        } else if (id.webkitRequestFullscreen) { /* Safari */
            id.webkitRequestFullscreen();
        } else if (id.msRequestFullscreen) { /* IE11 */
            id.msRequestFullscreen();
        } else if (id.mozRequestFullScreen) { /* Firefox */
            id.mozRequestFullScreen();
        } else if (id.webkitEnterFullscreen) { /* Chrome */
            id.webkitEnterFullscreen();
        } else if (id.webkitEnterFullScreen) { /* Chrome */
            id.webkitEnterFullScreen();
        }






    }

    const sideCards = [
        { Rank: 'A', Suit: 'D' },
        { Rank: 'K', Suit: 'D' },
        { Rank: 'Q', Suit: 'D' },
        { Rank: 'J', Suit: 'D' },
        { Rank: '10', Suit: 'D' },
    ]

    return (
        <>
            <div id='poker_table' className="relative bg-gradient-to-br from-slate-950 via-slate-800 to-slate-950 h-screen w-full mx-auto p-2 pt-5 flex justify-center items-start">
                <div className='mx-auto mt-3 px-3 container absolute top-0 left-0 bg-transparent font-extrabold ' >
                    <CloseIcon onClick={handleQuitLobby} className='cursor-pointer text-white ' />
                    <div className='flex flex-row items-center justify-between gap-1 absolute top-0 right-0 p-0 '>
                        <FullscreenIcon onClick={handleFullScreen} className='bg-slate-700 border-2 border-black font-extrabold shadow-white cursor-pointer hover:scale-105 active:scale-110 shadow-sm text-yellow-500 rounded-md' />
                        {/* <MicIcon className='bg-slate-700 border-2 border-black font-extrabold shadow-white cursor-pointer hover:scale-105 active:scale-110 shadow-sm text-yellow-500 rounded-md' /> */}
                    </div>
                </div>
                {/* bg-gradient-to-tr from-[#A67C00] via-[#FFD700] to-[#A67C00] */}
                <div className="poker_table_golden relative w-full h-[70vh] sm:h-[75vh] lg:h-[67vh]   border-2 border-gray-300 rounded-full shadow-xl  p-4 sm:p-4 md:p-4 lg:p-6 "
                // style={{
                //     backgroundImage: `url('https://as1.ftcdn.net/v2/jpg/01/62/74/18/1000_F_162741816_xRzkqyvs0QlxyLGystND2FjXW6IUP8XD.jpg')`,
                //     backgroundSize: 'cover',
                //     backgroundRepeat: 'no-repeat',
                //     backgroundPosition: 'center',
                //     boxShadow: `0px 5px 50px -4px #000 inset, 0px -7px 10px -4px #000 inset`
                // }}
                >



                    <div className="absolute    md:block top-1/2 left-1/4 -translate-x-1/2  -translate-y-1/2 text-slate-700 text-2xl font-bold opacity-30 h-36">
                        <img src={Banner} className='h-32 w-30' />
                    </div>
                    <div className="absolute    md:block top-1/2 right-1/4 translate-x-1/2  -translate-y-1/2 text-slate-700 text-2xl font-bold opacity-30 h-36">
                        <img src={Banner} className='h-32 w-30' />
                    </div>

                    <div className="h-full bg-gradient-to-tr from-green-950 via-green-900 to-green-950 border-1 rounded-full shadow-xl border-gray-400" style={{
                        boxShadow: `0px 5px 50px -4px #000 inset, 0px -7px 10px -4px #000 inset`
                    }}>



                        {/* Pot amount */}
                        <div id='pot_money' className={`flex absolute top-1/4 left-1/2 -translate-x-1/2 h-20 text-white font-bold text-lg`}>
                            <Dealer pot={gameMoveStatus?.potMoney || 0} cards={allCommunityCards} />
                        </div>
                        <div className='flex md:hidden w-full text-center flex-col absolute top-1/4 left-1/2 transform -translate-x-1/2 opacity-90'>
                            <h2 className=' sm:text-2xl md:text-6xl lg:text-6xl xl:text-6xl text-[#FFD700] w-full font-extrabold mt-2 font-banger'>Texas Hold'em </h2>
                        </div>
                        <div className='flex w-full text-center flex-col absolute bottom-1/4 left-1/2 transform -translate-x-1/2 opacity-90'>
                            <h2 className=' sm:text-2xl md:text-6xl lg:text-6xl xl:text-6xl text-[#FFD700] w-full font-extrabold mt-2 font-banger'>Texas Hold'em </h2>
                        </div>
                        {samplePlayers.map((player, index) => {
                            return <div id={`player_${player?.userId}`} className={`${player.fake ? getPlaceHolderPlayerPosition(index, samplePlayers.length) : getClassName(index, player.userId)} ${showResult && (gameResult.winnerAddress == player.address) && 'poker_winner_player z-10'}`} ref={el => usersRef.current[index] = el}>
                                <Player showResult={showResult} index={index} showDealer={player.userId === gameInfo?.dealerUserId} socket={socket} key={index} player={player} gameMoveStatus={gameMoveStatus} />
                            </div>
                        })}


                        <CommunityCards showResult={showResult} card={allCommunityCards} gameResult={gameResult} />
                    </div>
                    <div className=" flex mt-10 items-center justify-center w-full gap-4">
                        {showCurrentPlayerCards() && <CurrentPlayerCards gameMoveStatus={gameMoveStatus} player={currentUserPlayerCards[0] ? currentUserPlayerCards[0] : {}} />}
                    </div>
                </div>
                {/* Action buttons */}

                {showActionButtons() && <PokerActions animateFlowToPot={animateFlowToPot} minimumBetAmount={minimumBetAmount} gameMoveStatus={gameMoveStatus} socket={socket} />}
            </div>
        </>
    )
}

export default Home