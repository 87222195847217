import React, { useEffect, useState } from 'react';

import axiosInstance from '../../../../Config/axios';
import OpponentImage from "../../../../Image/opponent-matching.gif";
import { isEmpty } from '../../../../Config/function';

import RpsDivide from '../../../../assets/img/rps_divide.png';

import MeRockImage from '../../../../assets/img/games/me_rock2.svg';
// import OpponentRock from '../../../../assets/img/games/opponent_rock.svg';
import OpponentRock from '../../../../assets/img/games/opp_rock2.svg';

import MePaperImage from '../../../../assets/img/games/me_paper2.svg';
// import OpponentPaperImage from '../../../../assets/img/games/opponent_paper.svg';
import OpponentPaperImage from '../../../../assets/img/games/opp_paper2.svg';

import MeSciImage from '../../../../assets/img/games/me_scissor2.svg';
import OpponentSciImage from '../../../../assets/img/games/opp_scissor2.svg';
import { useSelector } from 'react-redux';
import RenderTimer from './RenderTimer';
import ActionButtons from './ActionButtons';
import WinnerRevealSound from '../../../../assets/audios/opponent_move.mp3';
import PlayerAmount from './PlayerAmount';
import LeaveFiveCardPokerModal from '../../../../CommonComponent/LeaveFiveCardPokerModal';
import { useNavigate } from 'react-router-dom';

function Game({ gameResult, gameInfo, socket, fetchGameInformation }) {
  const winnerRevealAudio = new Audio(WinnerRevealSound);

  const [quitConfirmation, setQuitConfirmation] = useState(false);
  const solPrice = useSelector(state => state.auth.solPrice);
  const userData = JSON.parse(localStorage.getItem('userData'));
  const [newCounter, setNewCounter] = useState(null);
  const [selectedMove, setSelectedMove] = useState(null)
  const { profileUrl } = userData;
  const navigate = useNavigate();


  useEffect(() => {
    if (!gameInfo) return;
    const { userMove } = gameInfo
    if (!userMove) return;

    if (userMove.choice) {
      setSelectedMove(userMove.choice)
    } else {
      setSelectedMove(null)
    }
  }, [gameInfo])

  const handleMove = async (move) => {
    try {
      setSelectedMove(move.name)
      const body = { gameAddress: gameInfo.gameAddress, move: move.name };
      const response = await axiosInstance.post('/api/game/move', body);
      const { data: responseData } = response;

      if (!responseData.status) {
        console.log(`Error: ${responseData.message}`);
      } else {
        console.log(responseData.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  }
  const getUserChoice = (userMove) => {
    if (!userMove) return null;
    const move = userMove
    if (move == 'rock') return MeRockImage;
    if (move == 'paper') return MePaperImage;
    if (move == 'scissors') return MeSciImage;
  }
  const getOpponentChoice = (userMove) => {
    if (!userMove) return null;
    const move = userMove.choice;

    if (move == 'rock') return OpponentRock;
    if (move == 'paper') return OpponentPaperImage;
    if (move == 'scissors') return OpponentSciImage;
  }

  const isGameDisabled = () => {
    if (!gameInfo?.opponentInfo?.amount && !gameInfo?.opponentInfo?.profileUrl) return true;
    if ((gameInfo?.userMove?.status == 'win' || gameInfo?.userMove?.status == 'lose' || gameInfo?.userMove?.status == 'draw') && (gameInfo?.opponentMove?.status == 'win' || gameInfo?.opponentMove?.status == 'lose' || gameInfo?.opponentMove?.status == 'draw')) return true;
    if (gameResult) return true;
    if (!gameInfo?.isStarted) return true;
    if (gameInfo?.userMove?.status == 'choosed') return true;
    return false;
  }

  const UsdPrice = ({ amount }) => {
    if (isEmpty(amount)) return null;
    let newAmount = Number(amount);
    if (isEmpty(solPrice)) return null;
    return <span className='m-0 p-0'>({(newAmount * solPrice).toFixed(2)} USD)</span>
  }


  useEffect(() => {
    if (!socket) return;

    socket.on('result', (data) => {
      setNewCounter(3);
    });
  }, [gameInfo]);


  useEffect(() => {
    if (!newCounter) return;
    const interval = setInterval(() => {

      if (newCounter === 1) {
        winnerRevealAudio.play();
        fetchGameInformation({ onResult: true })
      }

      setNewCounter(newCounter - 1);
    }, 1000);
    if (newCounter === 0) setNewCounter(null);
    return () => clearInterval(interval);
  }, [newCounter]);

  const handleToggleLeaveModal = async () => {
    setQuitConfirmation(!quitConfirmation);
  }

  const handleLeaveGame = async () => {
    try {
      navigate('/all-game-page')
    } catch (error) {
    }
  }

  return (
    <div className="flex relative h-[85%] border w-full bg-black text-white rounded-xl overflow-hidden">
      {gameInfo.opponentSelected && gameInfo.difference && !gameInfo?.userMove.choice && <RenderTimer gameInfo={gameInfo} />}

      <div id='rps_current_player' className="absolute top-5 left-5 flex items-center flex-col justify-center  space-x-4 ">
        <div className='flex items-center justify-center '>
          <img src={profileUrl} className='h-12 w-12 rounded-md border-2 border-white' alt='profile' />
          <div className='text-xs flex ml-2 flex-col items-start space-y-2'>
            <div className='text-md font-bold flex flex-row items-center justify-center'>{gameInfo?.userInfo?.userName}
              <span className='ml-2 bg-red-600 py-1 px-2 cursor-pointer rounded-md' onClick={handleToggleLeaveModal}>Quit</span>
            </div>
            <h3 className=' text-sm font-bold mb-0 mt-0'>
              {gameInfo?.userInfo?.amount ? <>
                <PlayerAmount amount={gameInfo?.userInfo?.amount} /> Sol </> : null}
              <UsdPrice amount={gameInfo?.userInfo?.amount} />
            </h3>
          </div>
        </div>

      </div>


      <div id='rps_opponent_player' className="absolute top-5 right-5 flex items-center space-x-4 ">
        <div className='text-xs flex flex-col items-end space-y-2'>
          <span className='text-md font-bold'>{gameInfo?.opponentInfo?.userName}</span>
          <h3 className='text-sm font-bold my-0'>
            {gameInfo?.opponentInfo?.amount ? <>
             *** Sol </> : null}
          </h3>
        </div>
        <img src={gameInfo?.opponentInfo?.profileUrl || OpponentImage} className='h-12 w-12 rounded-md border-white' alt='profile' />

      </div>

      <div className="rps_me h-full rounded-tl-xl rounded-bl-xl w-50 left-0 bottom-0 top-0 flex justify-between items-center">
        <div className="flex flex-col items-center space-y-4 absolute -left-10 w-50">
          {(selectedMove) ? (
            <img src={getUserChoice(selectedMove, 'self')} alt="Your move" className="w-full h-full" />
          ) : gameInfo?.isStarted ? (
            <img src={MeRockImage} alt="Opponent move" className="rps_me_waiting w-full h-full" />
          ) : (
            null
          )}
        </div>
      </div>
      <div className="h-full rounded-tl-xl rounded-bl-xl left-1/2 bottom-1/2 top-1/2 right-1/2 flex justify-between items-center">
        <img src={RpsDivide} alt="waiting" className="  " />
      </div>

      <div className="rps_opponent h-full overflow-hidden rounded-tr-xl rounded-br-xl w-50 -right-5 bottom-0 top-0 flex justify-between items-center">
        {!newCounter && <div className="flex flex-col items-center justify-center overflow-hidden space-y-4 absolute  -right-10 w-50 ">

          {(['choosed', 'win', 'lose', 'draw'].includes(gameInfo.opponentMove?.status)) && gameInfo?.opponentMove.choice ? (
            <img src={getOpponentChoice(gameInfo?.opponentMove)} alt="Opponent move" className="w-full h-full" />
          ) : (gameInfo?.isStarted && gameInfo?.opponentInfo?.profileUrl ? (
            <img src={OpponentRock} alt="Opponent move" className="rps_me_waiting w-full h-full" />
          ) : (
            null
          ))}

        </div>}
        {(!isEmpty(newCounter) && newCounter !== 0) && (<div className="flex flex-col items-center justify-center overflow-hidden space-y-4 absolute -right-10 w-50 ">

          <h1 className='text-9xl text-white font-bold'> {newCounter} </h1>

        </div>)}
      </div>



      <ActionButtons gameInfo={gameInfo} handleMove={handleMove} isGameDisabled={isGameDisabled} />
      {quitConfirmation && ''}
      {quitConfirmation && <LeaveFiveCardPokerModal show={quitConfirmation} handleClose={handleToggleLeaveModal} handleSubmit={handleLeaveGame} text='Are you sure you want to leave the game?' />}
    </div>
  );
}

export default Game;