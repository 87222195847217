import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import QuitLobby from '../../PokerTest/Components/Pages/QuitLobby'
import CommunityCards from '../../PokerTest/Components/Pages/Dealer/CommunityCard'
import Player from './Player'
import { useSelector } from 'react-redux'
import coins from '../../../../../assets/img/games/coins_yellow_transparent.png';
import { gsap } from 'gsap';
import { Flip } from 'gsap/Flip';
import Banner from "../../../../../assets/img/games/poker_background_image.png";
import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import Card from '../../PokerTest/Components/Pages/Cards'

const GameResult = ({ handlePlayAgain, gameInfo }) => {

  gsap.registerPlugin(Flip);

  const solPrice = useSelector(state => state.auth.solPrice)
  const winnerAddress = gameInfo?.gameResult?.winner
  const userData = JSON.parse(localStorage.getItem('userData'))

  const isWinner = winnerAddress === userData?.address
  const navigate = useNavigate()


  let allCommunityCards = []

  const { servedCards } = gameInfo

  if (servedCards) {
    allCommunityCards = [...servedCards['CommunityCards'], ...servedCards['CommunityCards1'], ...servedCards['CommunityCards2']]
  }

  let gameResult = gameInfo?.gameResult

  let gamePlayers = gameResult?.players || []


  const playerCards = gamePlayers.map(player => {
    let user = servedCards['PlayerCards'].filter(item => item['Name'] === player?.address)
    user = user[0];
    return { ...player, 'Hand': user['Hand'] }
  })



  const myProfile = playerCards.filter(player => player.address === userData?.address)

  const otherPlayers = playerCards.filter(player => player.address !== userData?.address)

  let allPlayers = playerCards
  if (myProfile) {
    allPlayers = [...myProfile, ...otherPlayers]
  }



  const getClassName = (index) => {
    if (index == 0) {
      return `absolute -bottom-10 left-1/2 -translate-x-1/2 -translate-y-1/2`;
    }
    if (index == 1) {
      return `absolute bottom-10 left-1/4  -translate-x-1/2`;
    }
    if (index == 2) {
      return `absolute left-5 bottom-1/4 translate-x-1/4`;
    }
    if (index == 3) {
      return `absolute left-0 top-1/4 translate-x-1/4`;
    }
    if (index == 4) {
      return `absolute top-0 left-1/4 -translate-x-1/4 `;
    }
    if (index == 5) {
      return `absolute top-0 right-1/4 translate-x-1/4 `;
    }
    if (index == 6) {
      return `absolute right-0 top-1/4 -translate-x-1/4`;
    }
    if (index == 7) {
      return `absolute right-0 bottom-1/4 -translate-x-1/4`;
    }
    if (index == 8) {
      return `absolute right-1/4 -bottom-0 translate-x-1/2 `;
    }
  }


  const handleQuitLobby = () => {
    navigate('/all-game-page');
  }

  const RenderUsd = ({ amount }) => {
    if (!solPrice) return null
    if (!amount) return null
    let newAmount = Number(amount)

    return <span className='text-sm text-white font-bold ms-2'>({(solPrice * newAmount).toFixed(2)} USD)</span>
  }

  const animateFlowToPot = (playerId, targetId, isCard) => {
    const playerElement = document.getElementById(playerId);
    const targetElement = document.getElementById(targetId);
    if (!playerElement || !targetElement) return;

    const playerRect = playerElement.getBoundingClientRect();
    const targetRect = targetElement.getBoundingClientRect();

    // Create a card element (or image)
    let card = null

    if (isCard) {
      card = document.createElement('span');
      card.className = 'card h-20 w-14 bg-red-400 rounded-md border-2 border-white rounded-md shadow-lg absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2';
      card.style.top = `${playerRect.top + window.scrollY}px`;
      card.style.left = `${playerRect.left + window.scrollX}px`;
    } else {
      card = document.createElement('img');
      card.className = 'card h-12 w-12 bg-transparent absolute rounded-full rounded-circle ';
      card.src = coins;
      card.style.top = `${playerRect.top + window.scrollY}px`;
      card.style.left = `${playerRect.left + window.scrollX}px`;
    }

    document.getElementById('poker_winner_table').appendChild(card);
    const x = targetRect.left + targetRect.width / 2 - playerRect.left - playerRect.width / 2;
    const y = targetRect.top + targetRect.height / 2 - playerRect.top - playerRect.height / 2;

    gsap.to(card, {
      duration: 1.3,
      x: x,
      y: y,
      onComplete: () => {
        card.remove();
      }
    });
  };

  const showCoinAnimation = () => {
    try {
      if (!gameResult) return
      const { players } = gameResult
      if (!players) return

      const winnerPlayer = players.filter(player => player.type === 'deposit')
      const otherPlayers = players.filter(player => player.type === 'withdrawal')
      for (let i = 0; i < otherPlayers.length; i++) {
        let player = otherPlayers[i]
        for (let j = 0; j < winnerPlayer.length; j++) {
          let winner = winnerPlayer[j]
          animateFlowToPot(`poker_result_player_${player.userId}`, `poker_result_player_${winner.userId}`, false)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    showCoinAnimation()
  }, [])


  const getPlaceHolderPlayerPosition = (index, totalPlayers) => {


    if (totalPlayers == 9) {
      if (index == 0) {
        return `absolute -bottom-10 left-1/2 -translate-x-1/2 -translate-y-1/2`;
      }
      if (index == 1) {
        return `absolute bottom-10 sm:bottom-0 left-1/4  -translate-x-1/2`;
      }
      if (index == 2) {
        return `absolute left-0 sm:left-5 bottom-1/4 `;
      }
      if (index == 3) {
        return `absolute left-0 sm:left-5 top-1/4 `;
      }
      if (index == 4) {
        return `absolute top-0 left-1/4 -translate-x-1/4 `;
      }
      if (index == 5) {
        return `absolute top-4 sm:top-0 right-1/4 translate-x-1/4 `;
      }
      if (index == 6) {
        return `absolute right-6 sm:right-16 top-1/4 `;
      }
      if (index == 7) {
        return `absolute right-6 sm:right-16 bottom-1/4`;
      }
      if (index == 8) {
        return `absolute right-1/4 bottom-10 sm:bottom-0 translate-x-1/2 `;
      }
      if (index == 9) {
        return `absolute right-1/4 bottom-10 sm:bottom-0 translate-x-1/2 `;
      }

    }

  };

  let samplePlayers = allPlayers
  if (samplePlayers.length == 2) {
    const firstPlayer = samplePlayers[0];
    const secondPlayer = samplePlayers[1];
    samplePlayers = [firstPlayer, { fake: true }, { fake: true }, { fake: true }, secondPlayer, { fake: true }, { fake: true }, { fake: true }, { fake: true }];
  } else if (samplePlayers.length == 3) {
    const firstPlayer = samplePlayers[0];
    const secondPlayer = samplePlayers[1];
    const thirdPlayer = samplePlayers[2];
    samplePlayers = [firstPlayer, { fake: true }, { fake: true }, secondPlayer, { fake: true }, { fake: true }, thirdPlayer, { fake: true }, { fake: true }];
  } else if (samplePlayers.length == 4) {
    const firstPlayer = samplePlayers[0];
    const secondPlayer = samplePlayers[1];
    const thirdPlayer = samplePlayers[2];
    const fourthPlayer = samplePlayers[3];
    samplePlayers = [firstPlayer, { fake: true }, { fake: true }, secondPlayer, { fake: true }, thirdPlayer, fourthPlayer, { fake: true }, { fake: true }];
  } else if (samplePlayers.length == 5) {
    const firstPlayer = samplePlayers[0];
    const secondPlayer = samplePlayers[1];
    const thirdPlayer = samplePlayers[2];
    const fourthPlayer = samplePlayers[3];
    const fifthPlayer = samplePlayers[4];
    samplePlayers = [firstPlayer, { fake: true }, { fake: true }, secondPlayer, thirdPlayer, fourthPlayer, fifthPlayer, { fake: true }, { fake: true }];
  } else if (samplePlayers.length == 6) {
    const firstPlayer = samplePlayers[0];
    const secondPlayer = samplePlayers[1];
    const thirdPlayer = samplePlayers[2];
    const fourthPlayer = samplePlayers[3];
    const fifthPlayer = samplePlayers[4];
    const sixthPlayer = samplePlayers[5];
    samplePlayers = [firstPlayer, { fake: true }, { fake: true }, secondPlayer, thirdPlayer, fourthPlayer, fifthPlayer, sixthPlayer, { fake: true }];
  } else if (samplePlayers.length == 7) {
    const firstPlayer = samplePlayers[0];
    const secondPlayer = samplePlayers[1];
    const thirdPlayer = samplePlayers[2];
    const fourthPlayer = samplePlayers[3];
    const fifthPlayer = samplePlayers[4];
    const sixthPlayer = samplePlayers[5];
    const seventhPlayer = samplePlayers[6];
    samplePlayers = [firstPlayer, { fake: true }, secondPlayer, thirdPlayer, fourthPlayer, fifthPlayer, sixthPlayer, seventhPlayer, { fake: true },];
  } else if (samplePlayers.length == 8) {
    const firstPlayer = samplePlayers[0];
    const secondPlayer = samplePlayers[1];
    const thirdPlayer = samplePlayers[2];
    const fourthPlayer = samplePlayers[3];
    const fifthPlayer = samplePlayers[4];
    const sixthPlayer = samplePlayers[5];
    const seventhPlayer = samplePlayers[6];
    const eighthPlayer = samplePlayers[7];
    samplePlayers = [firstPlayer, secondPlayer, thirdPlayer, fourthPlayer, fifthPlayer, sixthPlayer, seventhPlayer, eighthPlayer, { fake: true }];
  } else {
    samplePlayers = samplePlayers;
  }
  const handleFullScreen = () => {
    let id = document.getElementById('poker_winner_table')

    // if full screen is enable then disable it
    if (document.fullscreenElement || document.webkitFullscreenElement || document.msFullscreenElement || document.mozFullScreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) { /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE11 */
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) { /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) { /* Chrome */
        document.webkitCancelFullScreen();
      } else if (document.webkitCancelFullscreen) { /* Chrome */
        document.webkitCancelFullscreen();
      }
      return;
    }
    if (id.requestFullscreen) {
      id.requestFullscreen();
    } else if (id.webkitRequestFullscreen) { /* Safari */
      id.webkitRequestFullscreen();
    } else if (id.msRequestFullscreen) { /* IE11 */
      id.msRequestFullscreen();
    } else if (id.mozRequestFullScreen) { /* Firefox */
      id.mozRequestFullScreen();
    } else if (id.webkitEnterFullscreen) { /* Chrome */
      id.webkitEnterFullscreen();
    } else if (id.webkitEnterFullScreen) { /* Chrome */
      id.webkitEnterFullScreen();
    }
  }

  const sideCards = [
    { Rank: 'A', Suit: 'D' },
    { Rank: 'K', Suit: 'D' },
    { Rank: 'Q', Suit: 'D' },
    { Rank: 'J', Suit: 'D' },
    { Rank: '10', Suit: 'D' },
  ]


  return (
    <>
      {false && <div id='poker_winner_table' className='relative z-10 top-0 left-0 h-screen w-full '>
        <div className='relative rounded-lg px-5 py-2 block mx-auto h-screen container'>
          <QuitLobby message="If you quit, you will be out of the table" handleQuitLobby={handleQuitLobby} />

          <div className="w-full mt-5  bg-green-900 border-2 rounded-full p-4 flex justify-start items-center  h-[65%]">
            <div className='relative w-full h-full border-2  bg-gradient-to-r from-emerald-800 to-emerald-900 rounded-full'>
              <div className='absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/4'>
                <h2 className='text-white font-semibold'>
                  {!gameInfo?.gameResult?.isAllIn && (isWinner ?
                    <span className='text-white text-2xl flex flex-col justify-center items-center'>
                      <span className='text-green-500'>You won</span><br />
                      <div className='text-white mx-auto w-full text-center'>{Number(gameInfo.pot).toFixed(2)} Sol
                        <RenderUsd amount={gameInfo.pot} />
                      </div>
                    </span>
                    : null)
                  }
                </h2>

              </div>
              <div className='absolute w-1/2 block mx-auto top-1/2 left-0 transform translate-x-1/2 -translate-y-1/4'>
                <CommunityCards showResult={true} card={allCommunityCards} gameResult={gameInfo?.gameResult} />
              </div>

              {samplePlayers.map((player, index) => {
                return (
                  <div id={`player_${player?.userId}`} className={`${getClassName(index, player.userId)}`} >
                    <Player key={index} player={player} gameResult={gameInfo?.gameResult} />
                  </div>
                )
              })}

            </div>
          </div>
        </div>





      </div>}


      <div id='poker_winner_table' className="relative bg-gradient-to-br from-slate-950 via-slate-800 to-slate-950 h-screen w-full mx-auto p-2 pt-5 flex justify-start items-start flex-col">
        <div className='mx-auto mt-3 px-3 container absolute top-0 left-0 bg-transparent font-extrabold ' >
          <CloseIcon onClick={() => navigate(-1)} className='cursor-pointer text-white ' />
          <div className='flex flex-row items-center justify-between gap-1 absolute top-0 right-0 p-0 '>
            <FullscreenIcon onClick={handleFullScreen} className='bg-slate-700 border-2 border-black font-extrabold shadow-white cursor-pointer hover:scale-105 active:scale-110 shadow-sm text-yellow-500 rounded-md' />
          </div>
        </div>
        {/* bg-gradient-to-tr from-[#A67C00] via-[#FFD700] to-[#A67C00] */}
        <div className="poker_table_golden relative w-full h-[80vh] sm:h-[75vh] lg:h-[67vh]  border-2 border-gray-600 rounded-full shadow-xl  p-4 sm:p-4 md:p-4 lg:p-6 "
        // style={{
        //   backgroundImage: `url('https://as1.ftcdn.net/v2/jpg/01/62/74/18/1000_F_162741816_xRzkqyvs0QlxyLGystND2FjXW6IUP8XD.jpg')`,
        //   backgroundSize: 'cover',
        //   backgroundRepeat: 'no-repeat',
        //   backgroundPosition: 'center',
        //   boxShadow: `0px 5px 50px -4px #000 inset, 0px -7px 10px -4px #000 inset`
        // }}
        >


          <div className="absolute    md:block top-1/2 left-1/4 -translate-x-1/2  -translate-y-1/2 text-slate-700 text-2xl font-bold opacity-30 h-36">
            <img src={Banner} className='h-32 w-30' />
          </div>
          <div className="absolute    md:block top-1/2 right-1/4 translate-x-1/2  -translate-y-1/2 text-slate-700 text-2xl font-bold opacity-30 h-36">
            <img src={Banner} className='h-32 w-30' />
          </div>
          <div className='absolute w-full mx-auto md:w-3/4 top-1/2 left-0 md:left-1/2 md:-translate-x-1/2 transform -translate-y-1/4'>
            <CommunityCards showResult={true} card={allCommunityCards} gameResult={gameInfo?.gameResult} />
          </div>

          <div className=" h-full bg-gradient-to-tr from-green-950 via-green-900 to-green-950 border-1 rounded-full shadow-xl border-gray-400" style={{
            boxShadow: `0px 5px 50px -4px #000 inset, 0px -7px 10px -4px #000 inset`
          }}>

            <div className='flex md:hidden w-full text-center flex-col absolute top-1/4 left-1/2 transform -translate-x-1/2 opacity-90'>
              <h2 className=' sm:text-2xl md:text-6xl lg:text-6xl xl:text-6xl text-[#FFD700] w-full font-extrabold mt-2 font-banger'>Texas Hold'em </h2>
            </div>
            <div className='flex w-full text-center flex-col absolute bottom-1/4 left-1/2 transform -translate-x-1/2 opacity-90'>
              <h2 className=' sm:text-2xl md:text-6xl lg:text-6xl xl:text-6xl text-[#FFD700] w-full font-extrabold mt-2 font-banger'>Texas Hold'em </h2>
            </div>

            {samplePlayers.map((player, index) => {
              return <div id={`player_${player?.userId}`} className={`${player.fake ? getPlaceHolderPlayerPosition(index, samplePlayers.length) : getClassName(index, player.userId)}`} >
                <Player key={index} player={player} gameResult={gameInfo?.gameResult} />
              </div>
            })}


          </div>
         
        </div>
        <button onClick={handlePlayAgain} role='button' className='w-full md:w-1/2 absolute bottom-2 left-1/2 transform -translate-x-1/2 mx-auto bg-gradient-to-r from-[#FFD700] via-[#aa9414] to-[#FFD700] hover:bg-gradient-to-br focus:ring-4 focus:outline-none text-black py-2.5
     font-bold px-5 rounded-lg '>
          Press here to play/continue...
        </button>
      </div>
    </>
  )
}

export default GameResult
