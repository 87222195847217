import { Dialog } from '@mui/material'
import React, { useEffect, useState, useContext } from 'react'
import logo from "../../Image/banners_new.jpg"
import phan from "../../Image/Phantom-Logo-Purple.png"
import sol from "../../Image/solanaLogoMark.png"
import met from "../../Image/metamask.png"
import tru from "../../Image/trustwallet.png"
import compatible from "../../Image/evmcompatible.png"
import wall from "../../Image/wc.png"
import solLog from "../../Image/solanaVerticalLogo.png"
import bs58 from 'bs58';
import Authsuc from "../../Image/loginsuccess.png"
import Authfai from "../../Image/loginfailed.png"
// import { Staking } from '../Authentication/Context/StakingApp'
import { StakingApp } from '../Authentication/Context/StakingApp'
import { motion } from 'framer-motion';
import { Canvas } from '@react-three/fiber';
import { Stars, OrbitControls } from '@react-three/drei';
import { BiLinkExternal, BiCoinStack, BiUser } from 'react-icons/bi'


import ClearIcon from '@mui/icons-material/Clear';

// import ClearIcon from '@mui/icons-material/ArrowCircleLeft';
// import ClearIcon from '@mui/icons-material/ArrowBackIosNew';
import { ConnectionProvider, WalletProvider, useWallet } from '@solana/wallet-adapter-react';
import {
  WalletModalProvider,
  WalletDisconnectButton,
  WalletMultiButton
} from '@solana/wallet-adapter-react-ui';
import { useAccount, useSignMessage } from 'wagmi'
import axiosInstance from '../../Config/axios'

import { API_URLS } from '../../Config/apiUrls'
import { useNavigate } from 'react-router-dom'
import TwitterEvent from './TwitterTracking'


export default function Login({ open, setOpen }) {
  // const[openAuth,setAuth]=useState(false); 
  const { setOpentab, openTab, setAuth, setOpen1, open1, openAuth, Auth, wallets, setWallets } = useContext(StakingApp);
  const nevigate = useNavigate();
  const { data: signMessageData, isLoading, signMessage: mess } = useSignMessage();

  const { address, isConnected } = useAccount();
  const { wallet, connect, connecting, connected, select, publicKey, signMessage } = useWallet();
  const [signedMessage, setSignedMessage] = useState(null);

  // const[openTab,setOpentab]=useState(false); 
  const [openTab1, setOpentab1] = useState(false);
  // const[openAuth,setAuth]=useState(false); 
  const [text, setText] = useState("");
  const [nonce, setNonce] = useState(null);
  function handleCloseAll() {
    if (!wallet) {
      setOpen(false);
      setOpen1(false);
    }
  }
  function handleCloseAll1() {

    setOpen(false);
    setOpen1(false);

  }
  const SignIn = async () => {
    axiosInstance
      .get("/auth/generate-nonce")
      .then((res) => {
        setNonce(res.data.data.nonce);
        console.log(res, "response")
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (signMessageData) {

      Auth('ethereum', nonce, signMessageData, address);
    }
  }, [signMessageData])

  useEffect(() => {
    if (signedMessage) {

      Auth('solana', nonce, signedMessage, publicKey.toBase58());
    }
  }, [signedMessage])

  useEffect(() => {
    if (nonce) {
      if (wallets === 1) {

        handleSignMessage();
      }
      else handleSignMessageEVM();
    }
  }, [nonce]);

  const handleSignMessage = async () => {
    if (!publicKey || !signMessage) {
      alert('Wallet not connected or does not support message signing');
      return;
    }
    const message = nonce;
    const encodedMessage = new TextEncoder().encode(message);

    try {
      const signature = await signMessage(encodedMessage, "utf8");
      // const hexSignature = Array.from(signature).map(byte => ('0' + (byte & 0xFF).toString(16)).slice(-2)).join('');
      // const jsonSignature = Array.from(signature);
      // const hexSignature = "f073e82a5ae5044f65e6744b2629b375cfed8036fff7db9759e14a4f421f694316b3f69302f5a100aa2571b9ecee2c1ad3f5838a19bea2269436ae92fe7c010d";
      // const signatureBytes = hexSignature.match(/.{1,2}/g).map(byte => parseInt(byte, 16));
      // const signatureArray = Array.from(signatureBytes.slice(0, 64));
      // const signatureUint8Array = new Uint8Array(signatureArray);
      const signatureArray1 = Array.from(signature.slice(0, 64));
      const signatureString1 = signatureArray1.join(', ');
      setSignedMessage(bs58.encode(signature));
      console.log('Signed Message:', signature);
    } catch (error) {
      console.error('Error signing message:', error);
    }

  };


  // useEffect(()=>{
  // //  alert('hello0')
  //     setOpen1(true);

  // },[isConnected])

  console.log(wallet);

  function handleSolana() {
    if (!isConnected) {
      setWallets(1);
      setOpen1(true)

    } else {
      setText("your Evm wallet has benn connected,first you must disconnect evm wallet and then try to connect solana wallet.")
    }

  }
  function hgandleMetamask() {
    if (!connected) {
      setWallets(2);
      setOpen1(true)

    } else {
      setText("your solana wallet has been connected,first you must disconnect solana wallet and then try to connect evm wallet.")
    }

  }







  useEffect(() => {
    if (openTab) {
      setOpentab(false);
      setOpen(false);
      setOpen1(false);


    }
  }, [openTab]);

  function handleSignMessageEVM() {
    if (isConnected) {
      mess({
        message: nonce,
      });
    } else {
      alert("wallet not connected");
    }

  }


  return (
    <div className=''>
      {process.env.REACT_APP_ENVIRONMENT == 'prod' && <TwitterEvent />} 
      <Dialog
        open={open}
        onClose={setOpen}
        PaperProps={{
          className:
            "!min-w-[60%] !h-[50%]  flex gap-3 z-10 flex-col !rounded-2xl !text-shadow-none  !bg-black p-6",
        }}
      >

        <ClearIcon onClick={() => setOpen(false)} className='text-white z-50 float-left cursor-pointer' />
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className='items-center z-10 justify-center flex flex-row gap-20 bg-gradient-to-t from-[#51026f66] to-[#00000066] text-[#30000055] h-[100%] w-[100%]' style={{ overflowY: "auto", maxHeight: "100vh", scrollbarWidth: "none", msOverflowStyle: "none", "&::-webkit-scrollbar": { display: "none" } }}>
          <div className='flex justify-center flex-col gap-8 w-[50%] h-[100%] items-center'>
            <img src={logo} style={{ height: "250px", width: "auto", opacity: 'inherit', alignItems: "center" }} className='object-cover rounded-full'></img>
            {/* <h3 className='text-white text-center text-sm'>Welcome back, Poker Pro! Log in to your account to continue your poker journey. Enjoy thrilling PVP poker games and show off your skills!</h3> */}
          </div>
          <div className='flex justify-center items-center z-40 w-[50%] h-full m-4'>
            <div className='w-[100%] h-[100%] flex flex-col items-cente gap-8 justify-center'>
              <p className='text-center text-xs font-extrabold text-white'>Login/signIn direct with web3 wallet</p>
              <div className='flex justify-around row-span-2 gap-5 '>

                <div onClick={() => handleSolana()} className='flex justify-center bg-gradient-to-tr from-slate-600 via-slate-700 to-slate-900 h-20 w-full font-bold rounded-md hover:shadow-xl hover:shadow-[#00000055] flex-col cursor-pointer '><spam className="text-center text-white" >Solana wallet</spam>  <spam className="flex gap-5 flex-row justify-center items-center">     <img src={sol} style={{ height: "30px", width: "30px", alignItems: "center" }}></img>       <img src={phan} style={{ height: "20px", width: "80px", alignItems: "center" }}></img></spam></div>
              </div>
              <p className='text-red-500 font-semibold text-xs'>{text}</p>

            </div>

          </div>
          <div className="absolute inset-2 z-0">
            <Canvas>
              <Stars className="text-black" />
              <OrbitControls />
            </Canvas>
          </div>
        </motion.div>



      </Dialog>
      <Dialog
        open={open1}
        onClose={setOpen1}
        PaperProps={{


          className:
            "!min-w-[20%] !h-[50%]  p-5 flex gap-3 flex-col !rounded-2xl !text-shadow-none  !bg-black dispa",
        }}




      >
        <ClearIcon onClick={() => { setOpen1(false) }} className='text-white float-right cursor-pointer ' />
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className='items-center justify-center flex flex-col gap-5' style={{ overflowY: "auto", maxHeight: "100vh", scrollbarWidth: "none", msOverflowStyle: "none", "&::-webkit-scrollbar": { display: "none" } }}>
          {wallets === 1 ? <img src={solLog} style={{ height: "100px", width: "120px", alignItems: "center" }}></img> : <img src={compatible} style={{ height: "100px", width: "120px", alignItems: "center" }}></img>}

          <div className='w-[100%] h-[100%] flex flex-col  gap-12 '>
            <div className='flex flex-col gap-4'>
              <span className='flex flex-row gap-3'><h3 className='text-white font-bold'>STEP 1:</h3><p className='text-white text-lg'>Connect your wallet</p></span>
              {wallets === 2 ? <span onClick={() => handleCloseAll1()}>
                <w3m-button />
              </span> :
                <span onClick={() => handleCloseAll()}>
                  <WalletMultiButton />
                </span>}
            </div>
            <div className='flex flex-col gap-4'>
              <span className='flex flex-row gap-3'><h3 className='text-white font-bold'>STEP 2:</h3><p className='text-white text-lg'>Sign in the message for user authentication</p></span>
              {wallets === 1 ? <button onClick={() => SignIn()} class="bg-violet-700 hover:bg-violet-600 active:bg-violet-700 w-36 text-white h-12 font-bold focus:outline-none focus:ring focus:ring-violet-300 ...">
                Sign message
              </button> :
                <button onClick={() => SignIn()} class="bg-cyan-500 hover:bg-cyan-600 active:bg-cyan-700 w-36 text-white h-12 font-bold focus:outline-none focus:ring focus:ring-cyan-300 ...">
                  Sign message
                </button>}
            </div>

          </div>


        </motion.div>
      </Dialog>
      <Dialog
        open={openTab}
        onClose={setOpentab}
        PaperProps={{
          className:
            "!min-w-[20%] !h-[30%]  p-5 flex gap-3 flex-col !rounded-2xl !text-shadow-none  !bg-black",
        }}
      >
        <div className='flex h-[100%] w-[100%] justify-center items-center'>
          {openAuth ? <img src={Authsuc} style={{ height: "200px", width: "300px", alignItems: "center" }}></img> : <img src={Authfai} style={{ height: "200px", width: "300px", alignItems: "center" }}></img>}
        </div>
      </Dialog>
    </div>
  )

}
